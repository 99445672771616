export default [
  // Commitment of Non- discrimination
  {
    path: "/add-commitment-of-non-discrimination",
    name: "AddCommitmentOfNonDiscrimination",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddCommitmentOfNonDiscrimination",
      breadcrumb: [
        {
          text: "g.AddCommitmentOfNonDiscrimination",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-commitment-of-non-discrimination/:id",
    name: "EditCommitmentOfNonDiscrimination",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditCommitmentOfNonDiscrimination",
      breadcrumb: [
        {
          text: "g.EditCommitmentOfNonDiscrimination",
          active: true,
        },
      ],
    },
  },
  {
    path: "/commitment-of-non-discrimination-list",
    name: "CommitmentOfNonDiscriminationList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.CommitmentOfNonDiscriminationList",
      breadcrumb: [
        {
          text: "g.CommitmentOfNonDiscriminationList",
          active: true,
        },
      ],
    },
  },
  // The policy of certification body in dealing with client’s requests and the field of work

  {
    path: "/add-the-policy-of-certification-body",
    name: "AddThePolicyOfCBDCR",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.ThePolicyOfCBDCRList",
      breadcrumb: [
        {
          text: "g.ThePolicyOfCBDCRList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-the-policy-of-certification-body/:id",
    name: "EditThePolicyOfCBDCR",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditThePolicyOfCBDCR",
      breadcrumb: [
        {
          text: "g.EditThePolicyOfCBDCR",
          active: true,
        },
      ],
    },
  },
  {
    path: "/the-policy-of-certification-body-list",
    name: "ThePolicyOfCBDCRList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ThePolicyOfCBDCRList",
      breadcrumb: [
        {
          text: "g.ThePolicyOfCBDCRList",
          active: true,
        },
      ],
    },
  },

  // Risk management procedure
  {
    path: "/add-risk-management-procedure",
    name: "AddRiskManagementProcedure",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddRiskManagementProcedure",
      breadcrumb: [
        {
          text: "g.AddRiskManagementProcedure",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-risk-management-procedure/:id",
    name: "EditRiskManagementProcedure",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditRiskManagementProcedure",
      breadcrumb: [
        {
          text: "g.EditRiskManagementProcedure",
          active: true,
        },
      ],
    },
  },
  {
    path: "/risk-management-procedure-list",
    name: "RiskManagementProcedureList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.RiskManagementProcedureList",
      breadcrumb: [
        {
          text: "g.RiskManagementProcedureList",
          active: true,
        },
      ],
    },
  },
  // Risk evaluation of impartiality

  {
    path: "/add-risk-evaluation-of-impartiality",
    name: "AddRiskEvaluationOfImpartiality",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddRiskEvaluationOfImpartiality",
      breadcrumb: [
        {
          text: "g.AddRiskEvaluationOfImpartiality",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-risk-evaluation-of-impartiality/:id",
    name: "EditRiskEvaluationOfImpartiality",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditRiskEvaluationOfImpartiality",
      breadcrumb: [
        {
          text: "g.EditRiskEvaluationOfImpartiality",
          active: true,
        },
      ],
    },
  },
  {
    path: "/risk-evaluation-of-impartiality-list",
    name: "RiskEvaluationOfImpartialityList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.RiskEvaluationOfImpartialityList",
      breadcrumb: [
        {
          text: "g.RiskEvaluationOfImpartialityList",
          active: true,
        },
      ],
    },
  },

  // inform the GSO about rejection products
  {
    path: "/add-inform-the-GSO-about-rejection-products",
    name: "AddInformTheGSOAboutRejectionProducts",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddInformTheGSOAboutRejectionProducts",
      breadcrumb: [
        {
          text: "g.AddInformTheGSOAboutRejectionProducts",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-inform-the-GSO-about-rejection-products/:id",
    name: "EditInformTheGSOAboutRejectionProducts",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditInformTheGSOAboutRejectionProducts",
      breadcrumb: [
        {
          text: "g.EditInformTheGSOAboutRejectionProducts",
          active: true,
        },
      ],
    },
  },
  {
    path: "/inform-the-GSO-about-rejection-products-list",
    name: "InformTheGSOAboutRejectionProductsList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.InformTheGSOAboutRejectionProductsList",
      breadcrumb: [
        {
          text: "g.InformTheGSOAboutRejectionProductsList",
          active: true,
        },
      ],
    },
  },
];
