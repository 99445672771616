export default [
  {
    path: "/list-suppliers",
    name: "ListSuppliers",
    component: () => import("@/views/pages/suppliers/listSuppliers.vue"),
    meta: {
      pageTitle: "g.ListSuppliers",
      breadcrumb: [
        {
          text: "g.ListSuppliers",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-suppliers",
    name: "AddSuppliers",
    component: () => import("@/views/pages/suppliers/addSuppliers.vue"),
    meta: {
      pageTitle: "g.AddSupplier",
      breadcrumb: [
        {
          text: "g.AddSupplier",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-suppliers/:id",
    name: "EditSuppliers",
    component: () => import("@/views/pages/suppliers/addSuppliers.vue"),
    meta: {
      pageTitle: "g.EditSupplier",
      breadcrumb: [
        {
          text: "g.EditSupplier",
          active: true,
        },
      ],
    },
  },
  {
    path: "/view-supplier/:id",
    name: "ViewSupplier",
    component: () => import("@/views/pages/suppliers/viewSupplier.vue"),
    meta: {
      pageTitle: "g.ViewSupplier",
      breadcrumb: [
        {
          text: "g.ViewSupplier",
          active: true,
        },
      ],
    },
  },
];
