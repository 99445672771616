export default [
  // Inter lab. comparaison Protocol  -ILC Protocol
  {
    path: "/add-inter-lab-comparaison-protocol-ILC",
    name: "AddInterLabComparaisonProtocolILC",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddInterLabComparaisonProtocolILC",
      breadcrumb: [
        {
          text: "g.AddInterLabComparaisonProtocolILC",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-inter-lab-comparaison-protocol-ILC/:id",
    name: "EditInterLabComparaisonProtocolILC",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditInterLabComparaisonProtocolILC",
      breadcrumb: [
        {
          text: "g.EditInterLabComparaisonProtocolILC",
          active: true,
        },
      ],
    },
  },
  {
    path: "/inter-lab-comparaison-protocol-ILC-list",
    name: "InterLabComparaisonProtocolILCList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.InterLabComparaisonProtocolILCList",
      breadcrumb: [
        {
          text: "g.InterLabComparaisonProtocolILCList",
          active: true,
        },
      ],
    },
  },
  // Verification procedure
  {
    path: "/add-verification-procedure",
    name: "AddVerificationProcedure",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddVerificationProcedure",
      breadcrumb: [
        {
          text: "g.AddVerificationProcedure",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-verification-procedure-ILC/:id",
    name: "EditVerificationProcedure",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditVerificationProcedure",
      breadcrumb: [
        {
          text: "g.EditVerificationProcedure",
          active: true,
        },
      ],
    },
  },
  {
    path: "/verification-procedure-ILC-list",
    name: "VerificationProcedureList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.VerificationProcedureList",
      breadcrumb: [
        {
          text: "g.VerificationProcedureList",
          active: true,
        },
      ],
    },
  },
  // Insurance of tests with control charts
  {
    path: "/add-insurance-of-tests-with-control-charts",
    name: "AddInsuranceOfTestsWithControlCharts",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddInsuranceOfTestsWithControlCharts",
      breadcrumb: [
        {
          text: "g.AddInsuranceOfTestsWithControlCharts",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-insurance-of-tests-with-control-charts/:id",
    name: "EditInsuranceOfTestsWithControlCharts",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditInsuranceOfTestsWithControlCharts",
      breadcrumb: [
        {
          text: "g.EditInsuranceOfTestsWithControlCharts",
          active: true,
        },
      ],
    },
  },
  {
    path: "/insurance-of-tests-with-control-charts-list",
    name: "InsuranceOfTestsWithControlChartsList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.InsuranceOfTestsWithControlChartsList",
      breadcrumb: [
        {
          text: "g.InsuranceOfTestsWithControlChartsList",
          active: true,
        },
      ],
    },
  },
];
