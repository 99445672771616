export default [
    {
        path: "/add-lab",
        name: "AddLab",
        component: () => import("@/views/pages/labs/addLab.vue"),
        meta: {
            pageTitle: "g.addLab",
            breadcrumb: [
                {
                    text: "g.addLab",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/edit-lab/:id",
        name: "EditLab",
        component: () => import("@/views/pages/labs/addLab.vue"),
        meta: {
            pageTitle: "g.EditLab",
            breadcrumb: [
                {
                    text: "g.EditLab",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/list-of-labs",
        name: "ListOfLabs",
        component: () => import("@/views/pages/labs/labsList.vue"),
        meta: {
            pageTitle: "g.listOfLabs",
            breadcrumb: [
                {
                    text: "g.listOfLabs",
                    active: true,
                },
            ],
        },
    },
];