export default [
    {
        path: "/add-electric-type",
        name: "AddElectricType",
        component: () => import("@/views/pages/equipment/electricTypes/addElectricType.vue"),
        meta: {
            pageTitle: "g.addElectricType",
            breadcrumb: [
                {
                    text: "g.addElectricType",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/edit-electric-type/:id",
        name: "EditElectricType",
        component: () => import("@/views/pages/equipment/electricTypes/addElectricType.vue"),
        meta: {
            pageTitle: "g.editElectricType",
            breadcrumb: [
                {
                    text: "g.editElectricType",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/list-of-electric-types",
        name: "ElectricTypesList",
        component: () => import("@/views/pages/equipment/electricTypes/electricTypesList.vue"),
        meta: {
            pageTitle: "g.listOfElectricTypes",
            breadcrumb: [
                {
                    text: "g.listOfElectricTypes",
                    active: true,
                },
            ],
        },
    },
];