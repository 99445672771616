export default [
  // ** Sampling Disposal

  // Procedure of Sampling
  {
    path: "/add-procedure-of-sampling",
    name: "AddProcedureOfSampling",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddProcedureOfSampling",
      breadcrumb: [
        {
          text: "g.AddProcedureOfSampling",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-procedure-of-sampling/:id",
    name: "EditProcedureOfSampling",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditProcedureOfSampling",
      breadcrumb: [
        {
          text: "g.EditProcedureOfSampling",
          active: true,
        },
      ],
    },
  },
  {
    path: "/procedure-of-sampling-list",
    name: "ProcedureOfSamplingList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ProcedureOfSamplingList",
      breadcrumb: [
        {
          text: "g.ProcedureOfSamplingList",
          active: true,
        },
      ],
    },
  },
  // sample layout of reception, handling, storage & disposal
  {
    path: "/add-sample-layout-of-reception",
    name: "AddSampleLayoutOfReception",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddSampleLayoutOfReception",
      breadcrumb: [
        {
          text: "g.AddSampleLayoutOfReception",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-sample-layout-of-reception/:id",
    name: "EditSampleLayoutOfReception",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditSampleLayoutOfReception",
      breadcrumb: [
        {
          text: "g.EditSampleLayoutOfReception",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sample-layout-of-reception-list",
    name: "SampleLayoutOfReceptionList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.SampleLayoutOfReceptionList",
      breadcrumb: [
        {
          text: "g.SampleLayoutOfReceptionList",
          active: true,
        },
      ],
    },
  },
];
