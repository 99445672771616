export default [
  {
    path: "/add-improvement-request/:id/tech/:techId",
    name: "AddImprovementRequest",
    component: () => import("@/views/pages/requests/addImprovementRequest.vue"),
    meta: {
      pageTitle: "g.addImprovementRequest",
      breadcrumb: [
        {
          text: "g.addImprovementRequest",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-improvement-request/:id/tech/:techId",
    name: "EditImprovementRequest",
    component: () => import("@/views/pages/requests/addImprovementRequest.vue"),
    meta: {
      pageTitle: "g.editImprovementRequest",
      breadcrumb: [
        {
          text: "g.editImprovementRequest",
          active: true,
        },
      ],
    },
  },

  {
    path: "/review-requests-list/:id",
    name: "ReviewRequestsList",
    component: () => import("@/views/pages/requests/reviewRequestsList.vue"),
    meta: {
      pageTitle: "g.viewRequest",
      breadcrumb: [
        {
          text: "g.viewRequest",
          active: true,
        },
      ],
    },
  },

  {
    path: "/request-review/:id",
    name: "RequestReview",
    component: () => import("@/views/pages/requests/RequestReview.vue"),
    meta: {
      pageTitle: "g.RequestReview",
      breadcrumb: [
        {
          text: "g.RequestReview",
          active: true,
        },
      ],
    },
  },

  {
    path: "/add-tech-evaluation/:id/:tech_id",
    name: "AddTechEvaluation",
    component: () => import("@/views/pages/requests/techEvaluation.vue"),
    meta: {
      pageTitle: "g.theTechnicalEvaluation",
      breadcrumb: [
        {
          text: "g.theTechnicalEvaluation",
          active: true,
        },
      ],
    },
  },

  {
    path: "/view-tech-evaluation-report/:id/:tech_id",
    name: "ViewTechEvaluationReport",
    component: () => import("@/views/pages/requests/techEvaluation.vue"),
    meta: {
      pageTitle: "g.theTechnicalEvaluationReport",
      breadcrumb: [
        {
          text: "g.theTechnicalEvaluationReport",
          active: true,
        },
      ],
    },
  },

  {
    path: "/view-tech-evaluation/:id/tech/:tech_id",
    name: "ViewTechEvaluation",
    component: () =>
      import(
        "@/views/pages/requests/ViewTechEvaluation/viewTechEvaluation.vue"
      ),
    meta: {
      pageTitle: "g.viewTechEvaluation",
      breadcrumb: [
        {
          text: "g.viewTechEvaluation",
          active: true,
        },
      ],
    },
  },

  {
    path: "/edit-request-review/:id/review/:reviewId",
    name: "EditRequestReview",
    component: () => import("@/views/pages/requests/RequestReview.vue"),
    meta: {
      pageTitle: "g.EditRequestReview",
      breadcrumb: [
        {
          text: "g.EditRequestReview",
          active: true,
        },
      ],
    },
  },

  {
    path: "/add-corrective-action-request/:id/tech/:tech_id",
    name: "AddCorrectiveActionRequest",
    component: () =>
      import("@/views/pages/requests/addCorrectiveActionRequest.vue"),
    meta: {
      pageTitle: "g.addCorrectiveActionRequest",
      breadcrumb: [
        {
          text: "g.addCorrectiveActionRequest",
          active: true,
        },
      ],
    },
  },

  {
    path: "/add-sample/:id/tech/:tech_id",
    name: "AddSample",
    component: () => import("@/views/pages/requests/addSample.vue"),
    meta: {
      pageTitle: "g.addSample",
      breadcrumb: [
        {
          text: "g.addSample",
          active: true,
        },
      ],
    },
  },

  {
    path: "/add-tech-evaluations-reviews/:id",
    name: "AddTechEvaluationsReviews",
    component: () =>
      import(
        "@/views/pages/requests/techEvaluationsReviews/addTechEvaluationsReviews.vue"
      ),
    meta: {
      pageTitle: "g.AddTechEvaluationsReviews",
      breadcrumb: [
        {
          text: "g.AddTechEvaluationsReviews",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-tech-evaluations-reviews/:id/tech/:techId",
    name: "EditTechEvaluationsReviews",
    component: () =>
      import(
        "@/views/pages/requests/techEvaluationsReviews/addTechEvaluationsReviews.vue"
      ),
    meta: {
      pageTitle: "g.EditTechEvaluationsReviews",
      breadcrumb: [
        {
          text: "g.EditTechEvaluationsReviews",
          active: true,
        },
      ],
    },
  },
  {
    path: "/list-tech-evaluations-reviews/:id",
    name: "ListTechEvaluationsReviews",
    component: () =>
      import(
        "@/views/pages/requests/techEvaluationsReviews/listTechEvaluationsReviews.vue"
      ),
    meta: {
      pageTitle: "g.ListTechEvaluationsReviews",
      breadcrumb: [
        {
          text: "g.ListTechEvaluationsReviews",
          active: true,
        },
      ],
    },
  },
  {
    path: "/view-tech-evaluations-reviews/:id/tech/:techId",
    name: "ViewTechEvaluationsReviews",
    component: () =>
      import(
        "@/views/pages/requests/techEvaluationsReviews/viewTechEvaluationsReviews.vue"
      ),
    meta: {
      pageTitle: "g.ViewTechEvaluationsReviews",
      breadcrumb: [
        {
          text: "g.ViewTechEvaluationsReviews",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-final-Reviews/:id",
    name: "AddFinalReviews",
    component: () =>
      import("@/views/pages/requests/finalReviews/addFinalReviews.vue"),
    meta: {
      pageTitle: "g.AddFinalReviews",
      breadcrumb: [
        {
          text: "g.AddFinalReviews",
          active: true,
        },
      ],
    },
  },
  {
    path: "/list-final-Reviews/:id",
    name: "ListFinalReviews",
    component: () =>
      import("@/views/pages/requests/finalReviews/listFinalReviews.vue"),
    meta: {
      pageTitle: "g.ListFinalReviews",
      breadcrumb: [
        {
          text: "g.ListFinalReviews",
          active: true,
        },
      ],
    },
  },
  {
    path: "/service-request/:id/view-final-Reviews/:techId",
    name: "ViewFinalReviews",
    component: () =>
      import(
        "@/views/pages/requests/finalReviews/viewFinalReviews/viewFinalReviews.vue"
      ),
    meta: {
      pageTitle: "g.ViewFinalReviews",
      breadcrumb: [
        {
          text: "g.ViewFinalReviews",
          active: true,
        },
      ],
    },
  },
];
