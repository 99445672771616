export default [
    {
        path: "/add-category",
        name: "AddCategory",
        component: () => import("@/views/pages/categories/addCategory.vue"),
        meta: {
            pageTitle: "g.AddCategory",
            breadcrumb: [
                {
                    text: "g.AddCategory",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/edit-category/:id",
        name: "EditCategory",
        component: () => import("@/views/pages/categories/addCategory.vue"),
        meta: {
            pageTitle: "g.EditCategory",
            breadcrumb: [
                {
                    text: "g.EditCategory",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/list-of-categories",
        name: "ListOfCategories",
        component: () => import("@/views/pages/categories/categoriesList.vue"),
        meta: {
            pageTitle: "g.ListOfCategories",
            breadcrumb: [
                {
                    text: "g.ListOfCategories",
                    active: true,
                },
            ],
        },
    },
];

