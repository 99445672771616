export default [
  // Liability and financing
  // Evidence of  adequate insurance to cover liabilities arising from operations

  {
    path: "/add-liability-and-financing",
    name: "AddLiabilityAndFinancing",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.addLiabilityAndFinancing",
      breadcrumb: [
        {
          text: "g.addLiabilityAndFinancing",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-liability-and-financing/:id",
    name: "EditLiabilityAndFinancing",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.editLiabilityAndFinancing",
      breadcrumb: [
        {
          text: "g.editLiabilityAndFinancing",
          active: true,
        },
      ],
    },
  },
  {
    path: "/liability-and-financing-list",
    name: "LiabilityAndFinancingList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.liabilityAndFinancingList",
      breadcrumb: [
        {
          text: "g.liabilityAndFinancingList",
          active: true,
        },
      ],
    },
  },
];
