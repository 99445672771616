export default [
  // The policy of the certification body in dealing with the information
  //  (C)Certification (B)body (D)Dealing => AddThePolicy[CBD]
  {
    path: "/add-the-Policy-CBD",
    name: "AddThePolicyCBD",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddThePolicyCBD",
      breadcrumb: [
        {
          text: "g.AddThePolicyCBD",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-the-Policy-CBD/:id",
    name: "EditAddThePolicyCBD",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditAddThePolicyCBD",
      breadcrumb: [
        {
          text: "g.EditAddThePolicyCBD",
          active: true,
        },
      ],
    },
  },
  {
    path: "/the-Policy-CBD-list",
    name: "ThePolicyCBDList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ThePolicyCBDList",
      breadcrumb: [
        {
          text: "g.ThePolicyCBDList",
          active: true,
        },
      ],
    },
  },

  // Approval by the client to allow the publication of specific information
  {
    path: "/add-approval-by-the-client-to-allow-the-publication-of-specific-information",
    name: "ClientsConsentToPublish",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.ClientsConsentToPublish",
      breadcrumb: [
        {
          text: "g.ClientsConsentToPublish",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-approval-by-the-client-to-allow-the-publication-of-specific-information/:id",
    name: "EditClientsConsentToPublish",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditClientsConsentToPublish",
      breadcrumb: [
        {
          text: "g.EditClientsConsentToPublish",
          active: true,
        },
      ],
    },
  },
  {
    path: "/approval-by-the-client-to-allow-the-publication-of-specific-information-list",
    name: "ClientsConsentToPublishList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ClientsConsentToPublishList",
      breadcrumb: [
        {
          text: "g.ClientsConsentToPublishList",
          active: true,
        },
      ],
    },
  },
];
