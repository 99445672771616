export default [
  // Organizational structure of IB

  {
    path: "/add-organizational-structure-of-IB",
    name: "AddOrganizationalStructureOfIB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddOrganizationalStructureOfIB",
      breadcrumb: [
        {
          text: "g.AddOrganizationalStructureOfIB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-organizational-structure-of-IB/:id",
    name: "EditOrganizationalStructureOfIB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditOrganizationalStructureOfIB",
      breadcrumb: [
        {
          text: "g.EditOrganizationalStructureOfIB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/organizational-structure-of-IB-list",
    name: "OrganizationalStructureOfIBList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.OrganizationalStructureOfIBList",
      breadcrumb: [
        {
          text: "g.OrganizationalStructureOfIBList",
          active: true,
        },
      ],
    },
  },

  // Organizational structure of labs

  {
    path: "/add-organizational-structure-of-labs",
    name: "AddOrganizationalStructureOfLabs",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddOrganizationalStructureOfLabs",
      breadcrumb: [
        {
          text: "g.AddOrganizationalStructureOfLabs",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-organizational-structure-of-labs/:id",
    name: "EditOrganizationalStructureOfLabs",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditOrganizationalStructureOfLabs",
      breadcrumb: [
        {
          text: "g.EditOrganizationalStructureOfLabs",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/edit-organizational-structure-of-labs/:id",
  //   name: "EditOrganizationalStructureOfIB",
  //   component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
  //   meta: {
  //     pageTitle: "g.EditOrganizationalStructureOfIB",
  //     breadcrumb: [
  //       {
  //         text: "g.EditOrganizationalStructureOfIB",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/organizational-structure-of-labs-list",
    name: "OrganizationalStructureOfLabsList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.OrganizationalStructureOfLabsList",
      breadcrumb: [
        {
          text: "g.OrganizationalStructureOfLabsList",
          active: true,
        },
      ],
    },
  },

  // Organizational structure of  CB

  {
    path: "/add-organizational-structure-of-CB",
    name: "AddOrganizationalStructureOfCB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddOrganizationalStructureOfCB",
      breadcrumb: [
        {
          text: "g.AddOrganizationalStructureOfCB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-organizational-structure-of-CB/:id",
    name: "EditOrganizationalStructureOfCB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditOrganizationalStructureOfCB",
      breadcrumb: [
        {
          text: "g.EditOrganizationalStructureOfCB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/organizational-structure-of-CB-list",
    name: "OrganizationalStructureOfCBList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.OrganizationalStructureOfCBList",
      breadcrumb: [
        {
          text: "g.OrganizationalStructureOfCBList",
          active: true,
        },
      ],
    },
  },
];
