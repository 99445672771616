export default [
  {
    path: "/list-service-requests",
    name: "ListServiceRequests",
    component: () =>
      import("@/views/pages/serviceRequests/listServiceRequests.vue"),
    meta: {
      pageTitle: "g.ListServiceRequests",
      breadcrumb: [
        {
          text: "g.ListServiceRequests",
          active: true,
        },
      ],
    },
  },
  {
    path: "/view-service-request/:id",
    name: "ViewServiceRequest",
    component: () =>
      import(
        "@/views/pages/serviceRequests/viewServiceRequest/viewServiceRequest.vue"
      ),
    meta: {
      pageTitle: "g.ViewServiceRequest",
      breadcrumb: [
        {
          text: "g.ViewServiceRequest",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-service-request",
    name: "AddServiceRequest",
    component: () =>
      import("@/views/pages/serviceRequests/addServiceRequest.vue"),
    meta: {
      pageTitle: "g.AddServiceRequest",
      breadcrumb: [
        {
          text: "g.AddServiceRequest",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-service-request/:id",
    name: "EditServiceRequest",
    component: () =>
      import("@/views/pages/serviceRequests/addServiceRequest.vue"),
    meta: {
      pageTitle: "g.EditServiceRequest",
      breadcrumb: [
        {
          text: "g.EditServiceRequest",
          active: true,
        },
      ],
    },
  },
];
