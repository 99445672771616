export default [
  // Departments’ Responsibility of IB
  {
    path: "/add-departments-responsibility-of-IB",
    name: "AddDepartmentsResponsibilityOfIB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddDepartmentsResponsibilityOfIB",
      breadcrumb: [
        {
          text: "g.AddDepartmentsResponsibilityOfIB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-departments-responsibility-of-IB/:id",
    name: "EditDepartmentsResponsibilityOfIB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditDepartmentsResponsibilityOfIB",
      breadcrumb: [
        {
          text: "g.EditDepartmentsResponsibilityOfIB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/departments-responsibility-of-IB-list",
    name: "DepartmentsResponsibilityOfIBList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.DepartmentsResponsibilityOfIBList",
      breadcrumb: [
        {
          text: "g.DepartmentsResponsibilityOfIBList",
          active: true,
        },
      ],
    },
  },

  // Departments’ Responsibility of TB
  {
    path: "/add-departments-responsibility-of-TB",
    name: "AddDepartmentsResponsibilityOfTB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddDepartmentsResponsibilityOfTB",
      breadcrumb: [
        {
          text: "g.AddDepartmentsResponsibilityOfTB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-departments-responsibility-of-TB/:id",
    name: "EditDepartmentsResponsibilityOfTB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditDepartmentsResponsibilityOfTB",
      breadcrumb: [
        {
          text: "g.EditDepartmentsResponsibilityOfTB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/departments-responsibility-of-TB-list",
    name: "DepartmentsResponsibilityOfTBList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.DepartmentsResponsibilityOfTBList",
      breadcrumb: [
        {
          text: "g.DepartmentsResponsibilityOfTBList",
          active: true,
        },
      ],
    },
  },

  // Departments’ Responsibility of CB

  {
    path: "/add-departments-responsibility-of-CB",
    name: "AddDepartmentsResponsibilityOfCB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddDepartmentsResponsibilityOfCB",
      breadcrumb: [
        {
          text: "g.AddDepartmentsResponsibilityOfCB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-departments-responsibility-of-CB/:id",
    name: "EditDepartmentsResponsibilityOfCB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditDepartmentsResponsibilityOfCB",
      breadcrumb: [
        {
          text: "g.EditDepartmentsResponsibilityOfCB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/departments-responsibility-of-CB-list",
    name: "DepartmentsResponsibilityOfCBList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.DepartmentsResponsibilityOfCBList",
      breadcrumb: [
        {
          text: "g.DepartmentsResponsibilityOfCBList",
          active: true,
        },
      ],
    },
  },
];
