export default [
  // record of impartiality committee  personal
  {
    path: "/add-record-of-impartiality-committee-personal",
    name: "AddRecordOfImpartialityCommitteePersonal",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddRecordOfImpartialityCommitteePersonal",
      breadcrumb: [
        {
          text: "g.AddRecordOfImpartialityCommitteePersonal",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-record-of-impartiality-committee-personal/:id",
    name: "EditRecordOfImpartialityCommitteePersonal",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditRecordOfImpartialityCommitteePersonal",
      breadcrumb: [
        {
          text: "g.EditRecordOfImpartialityCommitteePersonal",
          active: true,
        },
      ],
    },
  },
  {
    path: "/record-of-impartiality-committee-personal",
    name: "RecordOfImpartialityCommitteePersonalList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.RecordOfImpartialityCommitteePersonal",
      breadcrumb: [
        {
          text: "g.RecordOfImpartialityCommitteePersonal",
          active: true,
        },
      ],
    },
  },

  // Plan of impartiality committee meeting

  {
    path: "/add-plan-of-impartiality-committee-meeting",
    name: "AddRecordOfImpartialityCommitteeMeeting",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddRecordOfImpartialityCommitteeMeeting",
      breadcrumb: [
        {
          text: "g.AddRecordOfImpartialityCommitteeMeeting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-plan-of-impartiality-committee-meeting/:id",
    name: "EditRecordOfImpartialityCommitteeMeeting",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditRecordOfImpartialityCommitteeMeeting",
      breadcrumb: [
        {
          text: "g.EditRecordOfImpartialityCommitteeMeeting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/plan-of-impartiality-committee-meeting",
    name: "RecordOfImpartialityCommitteeMeetingList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),

    meta: {
      pageTitle: "g.RecordOfImpartialityCommitteeMeeting",
      breadcrumb: [
        {
          text: "g.RecordOfImpartialityCommitteeMeeting",
          active: true,
        },
      ],
    },
  },
  // impartiality committee invitation
  {
    path: "/add-plan-of-impartiality-committee-meeting",
    name: "AddImpartialityCommitteeInvitation",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddImpartialityCommitteeInvitation",
      breadcrumb: [
        {
          text: "g.AddImpartialityCommitteeInvitation",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-plan-of-impartiality-committee-meeting/:id",
    name: "EditImpartialityCommitteeInvitation",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditImpartialityCommitteeInvitation",
      breadcrumb: [
        {
          text: "g.EditImpartialityCommitteeInvitation",
          active: true,
        },
      ],
    },
  },
  {
    path: "/impartiality-committee-invitation",
    name: "ImpartialityCommitteeInvitationList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),

    meta: {
      pageTitle: "g.ImpartialityCommitteeInvitation",
      breadcrumb: [
        {
          text: "g.ImpartialityCommitteeInvitation",
          active: true,
        },
      ],
    },
  },

  // Report  of impartiality committee meeting
  {
    path: "/add-report-of-impartiality-committee-meeting",
    name: "AddReportOfImpartialityCommitteeMeeting",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddReportOfImpartialityCommitteeMeeting",
      breadcrumb: [
        {
          text: "g.AddReportOfImpartialityCommitteeMeeting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-report-of-impartiality-committee-meeting/:id",
    name: "EditReportOfImpartialityCommitteeMeeting",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditReportOfImpartialityCommitteeMeeting",
      breadcrumb: [
        {
          text: "g.EditReportOfImpartialityCommitteeMeeting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/report-of-impartiality-committee-meeting",
    name: "ReportOfImpartialityCommitteeMeetingList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),

    meta: {
      pageTitle: "g.ReportOfImpartialityCommitteeMeeting",
      breadcrumb: [
        {
          text: "g.ReportOfImpartialityCommitteeMeeting",
          active: true,
        },
      ],
    },
  },

  // Certification Request Agreement
  {
    path: "/add-certification-request-agreement",
    name: "AddCertificationRequestAgreement",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddCertificationRequestAgreement",
      breadcrumb: [
        {
          text: "g.AddCertificationRequestAgreement",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-certification-request-agreement/:id",
    name: "EditCertificationRequestAgreement",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditCertificationRequestAgreement",
      breadcrumb: [
        {
          text: "g.EditCertificationRequestAgreement",
          active: true,
        },
      ],
    },
  },
  {
    path: "/certification-request-agreement",
    name: "CertificationRequestAgreementList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),

    meta: {
      pageTitle: "g.CertificationRequestAgreement",
      breadcrumb: [
        {
          text: "g.CertificationRequestAgreement",
          active: true,
        },
      ],
    },
  },

  // Control of Conformity Marking

  {
    path: "/add-control-of-conformity-marking",
    name: "AddControlOfConformityMarking",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddControlOfConformityMarking",
      breadcrumb: [
        {
          text: "g.AddControlOfConformityMarking",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-control-of-conformity-marking/:id",
    name: "EditControlOfConformityMarking",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditControlOfConformityMarking",
      breadcrumb: [
        {
          text: "g.EditControlOfConformityMarking",
          active: true,
        },
      ],
    },
  },
  {
    path: "/control-of-conformity-marking-list",
    name: "ControlOfConformityMarkingList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ControlOfConformityMarkingList",
      breadcrumb: [
        {
          text: "g.ControlOfConformityMarkingList",
          active: true,
        },
      ],
    },
  },
];
