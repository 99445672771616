export default [
  {
    path: "/add-user",
    name: "AddUser",
    component: () => import("@/views/pages/personnel/addUser.vue"),
    meta: {
      pageTitle: "g.AddUser",
      breadcrumb: [
        {
          text: "g.AddUser",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-user/:id",
    name: "EditUser",
    component: () => import("@/views/pages/personnel/addUser.vue"),
    meta: {
      pageTitle: "g.EditUser",
      breadcrumb: [
        {
          text: "g.EditUser",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users-list",
    name: "UsersList",
    component: () => import("@/views/pages/personnel/usersList.vue"),
    meta: {
      pageTitle: "g.UsersList",
      breadcrumb: [
        {
          text: "g.UsersList",
          active: true,
        },
      ],
    },
  },
];
