export default [
  // Quality Document
  {
    path: "/add-quality-document",
    name: "AddQualityDocument",
    component: () => import("@/views/pages/documents/addDocument.vue"),
    meta: {
      pageTitle: "g.AddQualityDocument",
      breadcrumb: [
        {
          text: "g.AddQualityDocument",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-quality-document/:id",
    name: "EditQualityDocument",
    component: () => import("@/views/pages/documents/addDocument.vue"),
    meta: {
      pageTitle: "g.EditQualityDocument",
      breadcrumb: [
        {
          text: "g.EditQualityDocument",
          active: true,
        },
      ],
    },
  },
  {
    path: "/quality-documents-list",
    name: "QualityDocumentList",
    component: () => import("@/views/pages/documents/documentsList.vue"),
    meta: {
      pageTitle: "g.QualityDocumentList",
      breadcrumb: [
        {
          text: "g.QualityDocumentList",
          active: true,
        },
      ],
    },
  },
  // ===================================================================
  {
    path: "/add-external-document",
    name: "AddExternalDocument",
    component: () => import("@/views/pages/documents/addDocument.vue"),
    meta: {
      pageTitle: "g.AddExternalDocument",
      breadcrumb: [
        {
          text: "g.AddExternalDocument",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-external-document/:id",
    name: "EditExternalDocument",
    component: () => import("@/views/pages/documents/addDocument.vue"),
    meta: {
      pageTitle: "g.EditExternalDocument",
      breadcrumb: [
        {
          text: "g.EditExternalDocument",
          active: true,
        },
      ],
    },
  },
  {
    path: "/external-documents-list",
    name: "ExternalDocumentList",
    component: () => import("@/views/pages/documents/documentsList.vue"),
    meta: {
      pageTitle: "g.ExternalDocumentList",
      breadcrumb: [
        {
          text: "g.ExternalDocumentList",
          active: true,
        },
      ],
    },
  },
  // Test Specifications Document
  {
    path: "/add-test-specifications-document",
    name: "AddTestSpecificationsDocument",
    component: () => import("@/views/pages/documents/addDocument.vue"),
    meta: {
      pageTitle: "g.AddTestSpecificationsDocument",
      breadcrumb: [
        {
          text: "g.AddTestSpecificationsDocument",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-test-specifications-document/:id",
    name: "EditTestSpecificationsDocument",
    component: () => import("@/views/pages/documents/addDocument.vue"),
    meta: {
      pageTitle: "g.EditTestSpecificationsDocument",
      breadcrumb: [
        {
          text: "g.EditTestSpecificationsDocument",
          active: true,
        },
      ],
    },
  },
  {
    path: "/test-specifications-documents-list",
    name: "TestSpecificationsDocumentList",
    component: () => import("@/views/pages/documents/documentsList.vue"),
    meta: {
      pageTitle: "g.TestSpecificationsDocumentList",
      breadcrumb: [
        {
          text: "g.TestSpecificationsDocumentList",
          active: true,
        },
      ],
    },
  },
  // View Document
  // {
  //   path: "/document/:id",
  //   name: "ViewDocument",
  //   component: () => import("@/views/pages/documents/viewDocument.vue"),
  //   meta: {
  //     pageTitle: "g.viewDocument",
  //     breadcrumb: [
  //       {
  //         text: "g.viewDocument",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
];
