export default {
  state: {
    permissions_: [],
    role: null,
    sub: {
      index: null,
      update: null,
      store: null,
      show: null,
      destroy: null,
      profile: null,
    },
    sub_Permissions: {},
  },
  getters: {},

  mutations: {
    UPDATE_STATE(state, payload) {
      state.permissions_ = payload;
    },
    UPDATE_ROLE(state, payload) {
      state.role = payload;
    },
  },
  actions: {
    GET_PERMISSION: ({ state }, payload) => {
      const data = state.permissions_.find((item) => item.name === payload);
      data?.subPermissions?.forEach(
        (item) => (state.sub[item.name] = item.is_checked)
      );
    },

    GET_PERMISSIONS: ({ state }, payload) => {
      const permissions = state.permissions_.filter((item) =>
        payload.includes(item.name)
      );

      if (permissions.length > 0) {
        payload?.map(
          (item, index) =>
            (state.sub_Permissions[item] = {
              ...permissions[index]?.subPermissions?.map((ele) => ({
                [ele.name]: ele.is_checked,
              })),
            })
        );
      }
    },
  },
};
