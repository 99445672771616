export default [
  {
    path: "/periodic-evaluation-of-technical-supervisors/:id",
    name: "PeriodicEvaluationOfTechnicalSupervisors",
    component: () => import("@/views/pages/evaluation/evaluation.vue"),
    meta: {
      pageTitle: "g.periodicEvaluationOfTechnicalSupervisors",
      breadcrumb: [
        {
          text: "g.periodicEvaluationOfTechnicalSupervisors",
          active: true,
        },
      ],
    },
  },
  {
    path: "/periodic-evaluation-of-the-general-manager-of-quality/:id",
    name: "PeriodicEvaluationOfTheGeneralManagerOfQuality",
    component: () => import("@/views/pages/evaluation/evaluation.vue"),
    meta: {
      pageTitle: "g.periodicEvaluationOfTheGeneralManagerOfQuality",
      breadcrumb: [
        {
          text: "g.periodicEvaluationOfTheGeneralManagerOfQuality",
          active: true,
        },
      ],
    },
  },
  {
    path: "/periodic-evaluation-of-the-financial-manager/:id",
    name: "PeriodicEvaluationOfTheFinancialManager",
    component: () => import("@/views/pages/evaluation/evaluation.vue"),
    meta: {
      pageTitle: "g.periodicEvaluationOfTheFinancialManager",
      breadcrumb: [
        {
          text: "g.periodicEvaluationOfTheFinancialManager",
          active: true,
        },
      ],
    },
  },
  {
    path: "/periodic-evaluation-of-the-technical-manager/:id",
    name: "PeriodicEvaluationOfTheTechnicalManager",
    component: () => import("@/views/pages/evaluation/evaluation.vue"),
    meta: {
      pageTitle: "g.periodicEvaluationOfTheTechnicalManager",
      breadcrumb: [
        {
          text: "g.periodicEvaluationOfTheTechnicalManager",
          active: true,
        },
      ],
    },
  },
  {
    path: "/periodic-evaluation-of-the-samples-services-supervisor/:id",
    name: "PeriodicEvaluationOfTheSamplesServicesSupervisor",
    component: () => import("@/views/pages/evaluation/evaluation.vue"),
    meta: {
      pageTitle: "g.periodicEvaluationOfTheSamplesServicesSupervisor",
      breadcrumb: [
        {
          text: "g.periodicEvaluationOfTheSamplesServicesSupervisor",
          active: true,
        },
      ],
    },
  },
];
