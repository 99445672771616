export default [
  {
    path: "/add-instruction-of-using-AB-logo",
    name: "AddInstructionOfUsingABLogo",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddInstructionOfUsingABLogo",
      breadcrumb: [
        {
          text: "g.AddInstructionOfUsingABLogo",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-instruction-of-using-AB-logo/:id",
    name: "EditInstructionOfUsingABLogo",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditInstructionOfUsingABLogo",
      breadcrumb: [
        {
          text: "g.EditInstructionOfUsingABLogo",
          active: true,
        },
      ],
    },
  },
  {
    path: "/instruction-of-using-AB-logo-list",
    name: "InstructionOfUsingABLogoList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.InstructionOfUsingABLogoList",
      breadcrumb: [
        {
          text: "g.InstructionOfUsingABLogoList",
          active: true,
        },
      ],
    },
  },
];
