export default [
  // Work Instruction
  {
    path: "/work-instruction",
    name: "AddWorkInstruction",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddWorkInstruction",
      breadcrumb: [
        {
          text: "g.AddWorkInstruction",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-work-instruction/:id",
    name: "EditWorkInstruction",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditWorkInstruction",
      breadcrumb: [
        {
          text: "g.EditWorkInstruction",
          active: true,
        },
      ],
    },
  },
  {
    path: "/work-instruction-list",
    name: "WorkInstructionList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.WorkInstructionList",
      breadcrumb: [
        {
          text: "g.WorkInstructionList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-equipment",
    name: "AddEquipment",
    component: () => import("@/views/pages/equipment/addEquipment.vue"),
  },
  {
    path: "/edit-equipment/:id",
    name: "editEquipment",
    component: () => import("@/views/pages/equipment/addEquipment.vue"),
    meta: {
      pageTitle: "g.editEquipment",
      breadcrumb: [
        {
          text: "g.editEquipment",
          active: true,
        },
      ],
    },
  },
  {
    path: "/equipment-card/:id",
    name: "EquipmentCard",
    component: () =>
      import("@/views/pages/equipment/equipmentCard/equipmentCard.vue"),
    meta: {
      pageTitle: "g.equipmentCard",
      breadcrumb: [
        {
          text: "g.equipmentCard",
          active: true,
        },
      ],
    },
  },
  {
    path: "/equipment-list",
    name: "EquipmentList",
    component: () => import("@/views/pages/equipment/equipmentList.vue"),
    meta: {
      pageTitle: "g.EquipmentList",
      breadcrumb: [
        {
          text: "g.EquipmentList",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/maintenance/:id",
  //   name: "Maintenance",
  //   component: () => import("@/views/pages/equipment/addEquipment.vue"),
  //   meta: {
  //     pageTitle: "g.maintenance",
  //     breadcrumb: [
  //       {
  //         text: "g.maintenance",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/add-maintenance-request",
    name: "AddMaintenanceRequest",
    component: () =>
      import("@/views/pages/equipment/equipmentCard/addMaintenanceRequest.vue"),
    meta: {
      pageTitle: "g.addMaintenanceRequest",
      breadcrumb: [
        {
          text: "g.addMaintenanceRequest",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-maintenance-request/:process_id",
    name: "EditMaintenanceRequest",
    component: () =>
      import("@/views/pages/equipment/equipmentCard/addMaintenanceRequest.vue"),
    meta: {
      pageTitle: "g.editMaintenanceRequest",
      breadcrumb: [
        {
          text: "g.editMaintenanceRequest",
          active: true,
        },
      ],
    },
  },

  {
    path: "/equipment-under-maintenance",
    name: "EquipmentUnderMaintenance",
    component: () =>
      import("@/views/pages/equipment/equipmentUnderMaintenance.vue"),
    meta: {
      pageTitle: "g.equipmentUnderMaintenance",
      breadcrumb: [
        {
          text: "g.equipmentUnderMaintenance",
          active: true,
        },
      ],
    },
  },

  // equipment in the lab

  {
    path: "/add-equipment-in-the-lab",
    name: "AddEquipmentInTheLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddEquipmentInTheLab",
      breadcrumb: [
        {
          text: "g.AddEquipmentInTheLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-equipment-in-the-lab/:id",
    name: "EditEquipmentInTheLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditEquipmentInTheLab",
      breadcrumb: [
        {
          text: "g.EditEquipmentInTheLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/equipment-in-the-lab-list",
    name: "EquipmentInTheLabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.EquipmentInTheLabList",
      breadcrumb: [
        {
          text: "g.EquipmentInTheLabList",
          active: true,
        },
      ],
    },
  },

  // *** Calibration ***
  // Procedure of Expand Calibration Certificate Period

  {
    path: "/add-procedure-of-expand-calibration-certificate-period",
    name: "AddProcedureOfExpandCalibrationCertificatePeriod",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddProcedureOfExpandCalibrationCertificatePeriod",
      breadcrumb: [
        {
          text: "g.AddProcedureOfExpandCalibrationCertificatePeriod",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-procedure-of-expand-calibration-certificate-period/:id",
    name: "EditProcedureOfExpandCalibrationCertificatePeriod",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditProcedureOfExpandCalibrationCertificatePeriod",
      breadcrumb: [
        {
          text: "g.EditProcedureOfExpandCalibrationCertificatePeriod",
          active: true,
        },
      ],
    },
  },

  {
    path: "/procedure-of-expand-calibration-certificate-period-list",
    name: "ProcedureOfExpandCalibrationCertificatePeriodList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ProcedureOfExpandCalibrationCertificatePeriodList",
      breadcrumb: [
        {
          text: "g.ProcedureOfExpandCalibrationCertificatePeriodList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/equipment-calibration-plan",
    name: "EquipmentCalibrationPlan",
    component: () =>
      import(
        "@/views/pages/equipment/calibration/equipmentCalibrationPlan.vue"
      ),
    meta: {
      pageTitle: "g.equipmentCalibrationPlan",
      breadcrumb: [
        {
          text: "g.equipmentCalibrationPlan",
          active: true,
        },
      ],
    },
  },
  {
    path: "/equipment-calibration-plan/:id",
    name: "EquipmentCalibrationPlanList",
    component: () =>
      import(
        "@/views/pages/equipment/calibration/equipmentCalibrationPlan.vue"
      ),
    meta: {
      pageTitle: "g.equipmentCalibrationPlan",
      breadcrumb: [
        {
          text: "g.equipmentCalibrationPlan",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-equipment-calibration-plan/:id",
    name: "AddEquipmentCalibrationPlan",
    component: () =>
      import(
        "@/views/pages/equipment/calibration/addEquipmentCalibrationPlan.vue"
      ),
  },
  {
    path: "/calibration-certificate-verifying/:id",
    name: "CalibrationCertificateVerifying",
    component: () =>
      import(
        "@/views/pages/equipment/calibration/verifying/calibrationCertificateVerifying.vue"
      ),
  },

  // Evaluates & Reviews the validity of Calibration Certificate and Capability of Expand the Period (Automatic adjustment method)
  {
    path: "/add-evaluates-and-reviews-the-validity-of-calibration",
    name: "AddEvaluatesAndReviewsTheValidityOfCalibrationCertificate",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddEvaluatesAndReviewsTheValidityOfCalibrationCertificate",
      breadcrumb: [
        {
          text: "g.AddEvaluatesAndReviewsTheValidityOfCalibrationCertificate",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-evaluates-and-reviews-the-validity-of-calibration/:id",
    name: "EditEvaluatesAndReviewsTheValidityOfCalibrationCertificate",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditEvaluatesAndReviewsTheValidityOfCalibrationCertificate",
      breadcrumb: [
        {
          text: "g.EditEvaluatesAndReviewsTheValidityOfCalibrationCertificate",
          active: true,
        },
      ],
    },
  },
  {
    path: "/evaluates-and-reviews-the-validity-of-calibration-list",
    name: "EvaluatesAndReviewsTheValidityOfCalibrationCertificateList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.EvaluatesAndReviewsTheValidityOfCalibrationCertificateList",
      breadcrumb: [
        {
          text: "g.EvaluatesAndReviewsTheValidityOfCalibrationCertificateList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/equipment-nominated-for-maintenance",
    name: "EquipmentNominatedForMaintenance",
    component: () =>
      import("@/views/pages/equipment/equipmentNominatedForMaintenance.vue"),
    meta: {
      pageTitle: "g.equipmentNominatedForMaintenance",
      breadcrumb: [
        {
          text: "g.equipmentNominatedForMaintenance",
          active: true,
        },
      ],
    },
  },
];
