export default [
  // Dealing with Technical Records

  {
    path: "/add-dealing-with-technical-records",
    name: "AddDealingWithTechnicalRecords",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddDealingWithTechnicalRecords",
      breadcrumb: [
        {
          text: "g.AddDealingWithTechnicalRecords",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-dealing-with-technical-records/:id",
    name: "EditDealingWithTechnicalRecords",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditDealingWithTechnicalRecords",
      breadcrumb: [
        {
          text: "g.EditDealingWithTechnicalRecords",
          active: true,
        },
      ],
    },
  },
  {
    path: "/dealing-with-technical-records-list",
    name: "DealingWithTechnicalRecordsList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.DealingWithTechnicalRecordsList",
      breadcrumb: [
        {
          text: "g.DealingWithTechnicalRecordsList",
          active: true,
        },
      ],
    },
  },
  // =================================================
  {
    path: "/control-of-technical-record-list",
    name: "ControlOfTechnicalRecordList",
    component: () => import("@/views/pages/documents/documentsList.vue"),
    meta: {
      pageTitle: "g.ControlOfTechnicalRecordList",
      breadcrumb: [
        {
          text: "g.ControlOfTechnicalRecordList",
          active: true,
        },
      ],
    },
  },{
    path: "/add-control-of-technical-record",
    name: "AddControlOfTechnicalRecord",
    component: () => import("@/views/pages/documents/addDocument.vue"),
    meta: {
      pageTitle: "g.AddControlOfTechnicalRecord",
      breadcrumb: [
        {
          text: "g.AddControlOfTechnicalRecord",
          active: true,
        },
      ],
    },
  },{
    path: "/edit-control-of-technical-record/:id",
    name: "EditControlOfTechnicalRecord",
    component: () => import("@/views/pages/documents/addDocument.vue"),
    meta: {
      pageTitle: "g.EditControlOfTechnicalRecord",
      breadcrumb: [
        {
          text: "g.EditControlOfTechnicalRecord",
          active: true,
        },
      ],
    },
  },
];
