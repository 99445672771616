export default [
  {
    path: "/add-temperature-and-humidity",
    name: "AddTemperatureAndHumidity",
    component: () =>
      import(
        "@/views/pages/facilitiesAndEnvironmentalConditions/temperatureAndHumidity/addTemperatureAndHumidity.vue"
      ),
    meta: {
      pageTitle: "g.addTemperatureAndHumidity",
      breadcrumb: [
        {
          text: "g.addTemperatureAndHumidity",
          active: true,
        },
      ],
    },
  },
  {
    path: "/temperature-and-humidity-list",
    name: "TemperatureAndHumidityList",
    component: () =>
      import(
        "@/views/pages/facilitiesAndEnvironmentalConditions/temperatureAndHumidity/temperatureAndHumidityList.vue"
      ),
    meta: {
      pageTitle: "g.temperatureAndHumidityList",
      breadcrumb: [
        {
          text: "g.temperatureAndHumidityList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/Authorized-to-use-computer",
    name: "AddAuthorizedToUseAComputer",
    component: () =>
      import(
        "@/views/pages/facilitiesAndEnvironmentalConditions/authorizedToUseAComputer/addAuthorizedToUseAComputer.vue"
      ),
    meta: {
      pageTitle: "g.addAuthorizedPersonsToDealWithTheLabPC",
      breadcrumb: [
        {
          text: "g.addAuthorizedPersonsToDealWithTheLabPC",
          active: true,
        },
      ],
    },
  },
  {
    path: "/authorized-to-use-computer-list",
    name: "authorizedToUseAComputerList",
    component: () =>
      import(
        "@/views/pages/facilitiesAndEnvironmentalConditions/authorizedToUseAComputer/authorizedToUseAComputerList.vue"
      ),
    meta: {
      pageTitle: "g.AuthorizedPersonsToDealWithTheLabPCList",
      breadcrumb: [
        {
          text: "g.AuthorizedPersonsToDealWithTheLabPCList",
          active: true,
        },
      ],
    },
  },

  // Authorized Person  in Assessment Body

  {
    path: "/add-authorized-person-in-assessment-body",
    name: "AddAuthorizedPersonInAssessmentBody",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddAuthorizedPersonInAssessmentBody",
      breadcrumb: [
        {
          text: "g.AddAuthorizedPersonInAssessmentBody",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-authorized-person-in-assessment-body/:id",
    name: "EditAuthorizedPersonInAssessmentBody",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditAuthorizedPersonInAssessmentBody",
      breadcrumb: [
        {
          text: "g.EditAuthorizedPersonInAssessmentBody",
          active: true,
        },
      ],
    },
  },
  {
    path: "/authorized-person-in-assessment-body-list",
    name: "AuthorizedPersonInAssessmentBodyList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.AuthorizedPersonInAssessmentBodyList",
      breadcrumb: [
        {
          text: "g.AuthorizedPersonInAssessmentBodyList",
          active: true,
        },
      ],
    },
  },
];
