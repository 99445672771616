export default [
  // Quality policy and objectives

  {
    path: "/add-quality-policy-and-objectives",
    name: "AddQualityPolicyAndObjectives",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddQualityPolicyAndObjectives",
      breadcrumb: [
        {
          text: "g.AddQualityPolicyAndObjectives",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-quality-policy-and-objectives/:id",
    name: "EditQualityPolicyAndObjectives",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditQualityPolicyAndObjectives",
      breadcrumb: [
        {
          text: "g.EditQualityPolicyAndObjectives",
          active: true,
        },
      ],
    },
  },
  {
    path: "/quality-policy-and-objectives-list",
    name: "QualityPolicyAndObjectivesList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.QualityPolicyAndObjectivesList",
      breadcrumb: [
        {
          text: "g.QualityPolicyAndObjectivesList",
          active: true,
        },
      ],
    },
  },
];
