export default [
  {
    path: "/add-complaint",
    name: "Complaint",
    component: () => import("@/views/pages/complaint/complaint.vue"),
  },
  {
    path: "/list-of-complaints",
    name: "ListOfComplaint",
    component: () => import("@/views/pages/complaint/listOfComplaint.vue"),
    meta: {
      pageTitle: "g.listOfComplaint",
      breadcrumb: [
        {
          text: "g.listOfComplaint",
          active: true,
        },
      ],
    },
  },
  {
    path: "/show-complaint/:id",
    name: "ShowComplaint",
    component: () =>
      import("@/views/pages/complaint/complaint.vue"),
    meta: {
      pageTitle: "g.complaintInformation",
      breadcrumb: [
        {
          text: "g.complaintInformation",
          active: true,
        },
      ],
    },
  },
  {
    path: "/investigation-details/:id",
    name: "InvestigationDetails",
    component: () =>
      import("@/views/pages/complaint/complaint.vue"),
    meta: {
      pageTitle: "g.InvestigationDetails",
      breadcrumb: [
        {
          text: "g.InvestigationDetails",
          active: true,
        },
      ],
    },
  },
  {
    path: "/assigned-complaint",
    name: "AssignedComplaint",
    component: () =>
      import("@/views/pages/complaint/assignedComplaint.vue"),
    meta: {
      pageTitle: "g.myComplaint",
      breadcrumb: [
        {
          text: "g.myComplaint",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/verification/:id",
  //   name: "Verification",
  //   component: () =>
  //     import("@/views/pages/complaint/verification/verification.vue"),
  //   meta: {
  //     pageTitle: "g.verification",
  //     breadcrumb: [
  //       {
  //         text: "g.verification",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
];
