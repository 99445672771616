export default [
  // SAITCO Legal entity
  {
    path: "/add-saitco-legal-entity",
    name: "AddSaitcoLegalEntity",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddSaitcoLegalEntity",
      breadcrumb: [
        {
          text: "g.AddSaitcoLegalEntity",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-saitco-legal-entity",
    name: "EditSaitcoLegalEntity",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditSaitcoLegalEntity",
      breadcrumb: [
        {
          text: "g.EditSaitcoLegalEntity",
          active: true,
        },
      ],
    },
  },
  {
    path: "/saitco-legal-entity-list",
    name: "SaitcoLegalEntityList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.SaitcoLegalEntityList",
      breadcrumb: [
        {
          text: "g.SaitcoLegalEntityList",
          active: true,
        },
      ],
    },
  },
  // Final License of ELE Lab

  {
    path: "/add-final-license-of-ELE-lab",
    name: "AddFinalLicenseOfELELab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddFinalLicenseOfELELab",
      breadcrumb: [
        {
          text: "g.AddFinalLicenseOfELELab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-final-license-of-ELE-lab",
    name: "EditFinalLicenseOfELELab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditFinalLicenseOfELELab",
      breadcrumb: [
        {
          text: "g.EditFinalLicenseOfELELab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/final-license-of-ELE-lab-list",
    name: "FinalLicenseOfELELabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.FinalLicenseOfELELabList",
      breadcrumb: [
        {
          text: "g.FinalLicenseOfELELabList",
          active: true,
        },
      ],
    },
  },

  // Final License of TEX Lab

  {
    path: "/add-final-license-of-TEX-lab",
    name: "AddFinalLicenseOfTEXLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddFinalLicenseOfTEXLab",
      breadcrumb: [
        {
          text: "g.AddFinalLicenseOfTEXLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-final-license-of-TEX-lab",
    name: "EditFinalLicenseOfTEXLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditFinalLicenseOfTEXLab",
      breadcrumb: [
        {
          text: "g.EditFinalLicenseOfTEXLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/final-license-of-TEX-lab-list",
    name: "FinalLicenseOfTEXLabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.FinalLicenseOfTEXLabList",
      breadcrumb: [
        {
          text: "g.FinalLicenseOfTEXLabList",
          active: true,
        },
      ],
    },
  },

  // Final License of GP Lab

  {
    path: "/add-final-license-of-GP-lab",
    name: "AddFinalLicenseOfGPLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddFinalLicenseOfGPLab",
      breadcrumb: [
        {
          text: "g.AddFinalLicenseOfGPLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-final-license-of-GP-lab",
    name: "EditFinalLicenseOfGPLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditFinalLicenseOfGPLab",
      breadcrumb: [
        {
          text: "g.EditFinalLicenseOfGPLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/final-license-of-GP-lab-list",
    name: "FinalLicenseOfGPLabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.FinalLicenseOfGPLabList",
      breadcrumb: [
        {
          text: "g.FinalLicenseOfGPLabList",
          active: true,
        },
      ],
    },
  },

  // Primary /Final License of cosmetics Lab

  {
    path: "/add-primary-final-license-of-cosmetics-lab",
    name: "AddPrimaryFinalLicenseOfCosmeticsLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddPrimaryFinalLicenseOfCosmeticsLab",
      breadcrumb: [
        {
          text: "g.AddPrimaryFinalLicenseOfCosmeticsLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-primary-final-license-of-cosmetics-lab",
    name: "EditPrimaryFinalLicenseOfCosmeticsLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditPrimaryFinalLicenseOfCosmeticsLab",
      breadcrumb: [
        {
          text: "g.EditPrimaryFinalLicenseOfCosmeticsLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/primary-final-license-of-cosmetics-lab-list",
    name: "PrimaryFinalLicenseOfCosmeticsLabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.PrimaryFinalLicenseOfCosmeticsLabList",
      breadcrumb: [
        {
          text: "g.PrimaryFinalLicenseOfCosmeticsLabList",
          active: true,
        },
      ],
    },
  },

  // Primary Final License of SP Lab

  {
    path: "/add-primary-final-license-of-SP-lab",
    name: "AddPrimaryFinalLicenseOfSPLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddPrimaryFinalLicenseOfSPLab",
      breadcrumb: [
        {
          text: "g.AddPrimaryFinalLicenseOfSPLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-primary-final-license-of-SP-lab",
    name: "EditPrimaryFinalLicenseOfSPLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditPrimaryFinalLicenseOfSPLab",
      breadcrumb: [
        {
          text: "g.EditPrimaryFinalLicenseOfSPLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/primary-final-license-of-SP-lab-list",
    name: "PrimaryFinalLicenseOfSPLabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.PrimaryFinalLicenseOfSPLabList",
      breadcrumb: [
        {
          text: "g.PrimaryFinalLicenseOfSPLabList",
          active: true,
        },
      ],
    },
  },

  // License of China branch

  {
    path: "/add-license-of-china-branch",
    name: "AddLicenseOfChinaBranch",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddLicenseOfChinaBranch",
      breadcrumb: [
        {
          text: "g.AddLicenseOfChinaBranch",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-license-of-china-branch",
    name: "EditLicenseOfChinaBranch",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditLicenseOfChinaBranch",
      breadcrumb: [
        {
          text: "g.EditLicenseOfChinaBranch",
          active: true,
        },
      ],
    },
  },
  {
    path: "/license-of-china-branch-list",
    name: "LicenseOfChinaBranchList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.LicenseOfChinaBranchList",
      breadcrumb: [
        {
          text: "g.LicenseOfChinaBranchList",
          active: true,
        },
      ],
    },
  },
];
