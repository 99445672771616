export default [
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/pages/account/AccountSetting.vue"),
    meta: {
      pageTitle: "Profile",
      breadcrumb: [
        {
          text: "Profile",
          active: true,
        },
      ],
    },
  },
];
