export default [
  {
    path: "/clients-list",
    name: "ClientsList",
    component: () => import("@/views/pages/clients/clientsList.vue"),
    meta: {
      pageTitle: "g.ClientsList",
      breadcrumb: [
        {
          text: "g.ClientsList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/view-client/:id",
    name: "ViewClient",
    component: () => import("@/views/pages/clients/viewClient.vue"),
    meta: {
      pageTitle: "g.ViewClient",
      breadcrumb: [
        {
          text: "g.ViewClient",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-client",
    name: "AddClient",
    component: () => import("@/views/pages/clients/addClients.vue"),
    meta: {
      pageTitle: "g.addClient",
      breadcrumb: [
        {
          text: "g.addClient",
          active: true,
        },
      ],
    },
  },

  {
    path: "/edit-client/:id",
    name: "EditClient",
    component: () => import("@/views/pages/clients/addClients.vue"),
    meta: {
      pageTitle: "g.EditClient",
      breadcrumb: [
        {
          text: "g.EditClient",
          active: true,
        },
      ],
    },
  },
];
