export default [
  // change the path page from -> /opportunitiesAndRisk/listOfOpportunitiesAndRisks.vue to -> /riskTypes/listRiskTypes.vue
  {
    path: "/list-of-risks",
    name: "ListOfRisks",
    component: () => import("@/views/pages/riskTypes/listRiskTypes.vue"),
    meta: {
      pageTitle: "g.ListOfRisks",
      breadcrumb: [{ text: "g.ListOfRisks", active: true }],
    },
  },
  // * new routes
  {
    path: "/add-risk-type",
    name: "AddRiskType",
    component: () => import("@/views/pages/riskTypes/addRiskType.vue"),
    meta: {
      pageTitle: "g.AddRiskType",
      breadcrumb: [{ text: "g.AddRiskType", active: true }],
    },
  },
  {
    path: "/edit-risk-type/:id",
    name: "EditRiskType",
    component: () => import("@/views/pages/riskTypes/addRiskType.vue"),
    meta: {
      pageTitle: "g.EditRiskType",
      breadcrumb: [{ text: "g.EditRiskType", active: true }],
    },
  },

  // **
];
