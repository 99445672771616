import Vue from "vue";
// axios
import axios from "axios";
import router from "@/router";


const AUTHTOKEN = localStorage.getItem("accessToken");
const locale = localStorage.getItem("lang");

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: "https://saitco-b.geexar.dev/api/v1/",
  headers: {
    Authorization: `Bearer ${AUTHTOKEN}`,
    "x-locale": locale,
    "X-Page-Size": "all",
  },
});

Vue.prototype.$http = axiosIns;

axiosIns.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      // handle go to login
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userData");

      router.push({ name: "Login" });
    } else {
      return Promise.reject(error);
    }
  }
);


export default axiosIns;
