import axiosIns from "@axios";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import userAbility from "@/libs/acl";
import useJwt from "@/auth/jwt/useJwt";
import i18n from "@/libs/i18n";

import router from "@/router";

export default {
  state: {
    username: "",
    password: "",
  },
  getters: {},
  mutations: {
    SET_PASSWORD(state, password) {
      state.password = password;
    },

    SET_USERNAME(state, username) {
      state.username = username;
    },

    CHECK_USER_ABILITY(state, payload) {
      if (payload == "Super Admin") {
        userAbility.update([
          {
            action: "manage",
            subject: "all",
          },
        ]);
      }
    },
  },
  actions: {
    FETCH_LOGIN({ state, commit }) {
      const formData = new FormData();
      formData.append("email", state.username);
      formData.append("password", state.password);

      axiosIns
        .post("auth/login", formData)
        .then((response) => {
          if (response.status === 200) {
            const { user } = response.data.data;
            state.user = user;

            useJwt.setToken(response.data.data.token);
            /*
              TODO: We shouldn't save user data in local storage as it isn't safe, we must create a modal for user that is updated automatically and without the need for localstorage 
            */
            localStorage.setItem("userData", JSON.stringify(user));
            commit("CHECK_USER_ABILITY", user.role);

            toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: i18n.t(`login_welcome_message`),
                icon: "CheckCircleIcon",
                variant: "success",
                // text: `${i18n.t("login_welcome_text")} ${user.role}`,
                text: i18n.t("login_welcome_text", { role: user.role }),
              },
            });

            router.replace(getHomeRouteForLoggedInUser(user.role));
          }
          if (response.status === 201 && !response.data?.email_verify) {
            toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: i18n.t("email_verify"),
              },
            });
            handelCheckEmail(state.username);
          }
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: i18n.t("login_auth_error"),
            },
          });
        });
    },
  },
};

export const handelCheckEmail = (email) => {
  const formData = new FormData();
  formData.append("email", email);
  axiosIns
    .post("check-email", formData)
    .then((response) => {
      if (response.status === 200) {
        toast({
          component: response.data.message,
          position: "top-right",
          props: {
            title: `send email`,
            icon: "CheckCircleIcon",
            variant: "success",
            text: "places check your email",
          },
        });
      }
    })
    .catch((err) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Error`,
          icon: "AlertTriangleIcon",
          variant: "danger",
          text: "Please try again",
        },
      });
    });
};
