import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

// Routes
import personnel from "./routes/personnel";
import evaluation from "./routes/evaluation";
import opportunitiesAndRisk from "./routes/opportunitiesAndRisk";
import complaint from "./routes/complaint";
import models from "./routes/models";
import requests from "./routes/requests";
import impartiality from "./routes/impartiality";
import nonDiscriminatoryConditions from "./routes/non-discriminatoryConditions";
import publiclyAvailableInformation from "./routes/publiclyAvailableInformation";
import legalAndContractualMatters from "./routes/legalAndContractualMatters";
import DepartmentsResponsibilityOfIB from "./routes/DepartmentsResponsibilityOfIB";
import scope from "./routes/scope";
import receivingSamples from "./routes/samples";
import organizationalStructureOfIB from "./routes/organizationalStructureOfIB";
import mechanismForSafeguardingImpartiality from "./routes/mechanismForSafeguardingImpartiality";
import equipment from "./routes/equipment";
import electricType from "./routes/electricType";
import maintenanceTypes from "./routes/maintenanceTypes";
import confidentiality from "./routes/confidentiality";
import qualityPolicyAndObjectives from "./routes/qualityPolicyAndObjectives";
import selectionAndVerificationAndValidationOfMethods from "./routes/selectionAndVerificationAndValidationOfMethods";
import TechnicalRecords from "./routes/TechnicalRecords";
import ensuringTheValidityOfResults from "./routes/ensuringTheValidityOfResults";
import reportingOfResults from "./routes/reportingOfResults";
import labs from "./routes/labs";
import serviceRequests from "./routes/serviceRequests";
import correctActionType from "./routes/correctActionType";
import clients from "./routes/clients";
import roles from "./routes/roles";
import suppliers from "./routes/suppliers";
import categories from "./routes/categories";
import profile from "./routes/profile";
import facilitiesAndEnvironmentalConditions from "./routes/facilitiesAndEnvironmentalConditions";
import documents from "./routes/documents";
import externallyProvidedProductsAndServices from "./routes/externallyProvidedProductsAndServices";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/pages/home/index.vue"),
      meta: {
        pageTitle: "g.home",
        breadcrumb: [
          {
            text: "g.home",
            active: true,
          },
        ],
      },
    },
    ...personnel,
    ...evaluation,
    ...opportunitiesAndRisk,
    ...complaint,
    ...receivingSamples,
    ...models,
    ...requests,
    ...impartiality,
    ...nonDiscriminatoryConditions,
    ...publiclyAvailableInformation,
    ...legalAndContractualMatters,
    ...DepartmentsResponsibilityOfIB,
    ...scope,
    ...organizationalStructureOfIB,
    ...mechanismForSafeguardingImpartiality,
    ...equipment,
    ...electricType,
    ...maintenanceTypes,
    ...confidentiality,
    ...qualityPolicyAndObjectives,
    ...selectionAndVerificationAndValidationOfMethods,
    ...TechnicalRecords,
    ...ensuringTheValidityOfResults,
    ...labs,
    ...reportingOfResults,
    ...serviceRequests,
    ...correctActionType,
    ...clients,
    ...roles,
    ...suppliers,
    ...categories,
    ...facilitiesAndEnvironmentalConditions,
    ...profile,
    ...documents,
    ...externallyProvidedProductsAndServices,
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/authentication/Login.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "Login" });
  }
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "Login" });
  }

  store.commit("SET_ROUTE", to);

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
