export default [
  // Confidentiality and  impartiality Commitment

  {
    path: "/add-confidentiality-and-imartiality-commitment",
    name: "AddConfidentialityAndImpartialityCommitment",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddConfidentialityAndImpartialityCommitment",
      breadcrumb: [
        {
          text: "g.AddConfidentialityAndImpartialityCommitment",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-confidentiality-and-imartiality-commitment/:id",
    name: "EditConfidentialityAndImpartialityCommitment",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditConfidentialityAndImpartialityCommitment",
      breadcrumb: [
        {
          text: "g.EditConfidentialityAndImpartialityCommitment",
          active: true,
        },
      ],
    },
  },
  {
    path: "/confidentiality-and-imartiality-commitment-list",
    name: "ConfidentialityAndImpartialityCommitmentList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ConfidentialityAndImpartialityCommitmentList",
      breadcrumb: [
        {
          text: "g.ConfidentialityAndImpartialityCommitmentList",
          active: true,
        },
      ],
    },
  },

  // Release confidential information

  {
    path: "/add-Release-confidential-information",
    name: "AddReleaseConfidentialInformation",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddReleaseConfidentialInformation",
      breadcrumb: [
        {
          text: "g.AddReleaseConfidentialInformation",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-Release-confidential-information/:id",
    name: "EditReleaseConfidentialInformation",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditReleaseConfidentialInformation",
      breadcrumb: [
        {
          text: "g.EditReleaseConfidentialInformation",
          active: true,
        },
      ],
    },
  },
  {
    path: "/release-confidential-information-list",
    name: "ReleaseConfidentialInformationList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ReleaseConfidentialInformationList",
      breadcrumb: [
        {
          text: "g.ReleaseConfidentialInformationList",
          active: true,
        },
      ],
    },
  },
];
