export default [
  {
    path: "/list-roles",
    name: "ListRoles",
    component: () => import("@/views/pages/roles/listRoles.vue"),
    meta: {
      pageTitle: "g.listRoles",
      breadcrumb: [
        {
          text: "g.listRoles",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-role",
    name: "AddRole",
    component: () => import("@/views/pages/roles/addRole.vue"),
    meta: {
      pageTitle: "g.addRole",
      breadcrumb: [
        {
          text: "g.addRole",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-role/:id",
    name: "EditRole",
    component: () => import("@/views/pages/roles/addRole.vue"),
    meta: {
      pageTitle: "g.editRole",
      breadcrumb: [
        {
          text: "g.editRole",
          active: true,
        },
      ],
    },
  },
];
