export default [
  //Acceptance and Rejection Criteria

  {
    path: "/add-acceptance-and-rejection-criteria",
    name: "AddAcceptanceAndRejectionCriteria",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddAcceptanceAndRejectionCriteria",
      breadcrumb: [
        {
          text: "g.AddAcceptanceAndRejectionCriteria",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-acceptance-and-rejection-criteria/:id",
    name: "EditAcceptanceAndRejectionCriteria",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditAcceptanceAndRejectionCriteria",
      breadcrumb: [
        {
          text: "g.EditAcceptanceAndRejectionCriteria",
          active: true,
        },
      ],
    },
  },
  {
    path: "/acceptance-and-rejection-criteria-list",
    name: "AcceptanceAndRejectionCriteriaList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.AcceptanceAndRejectionCriteriaList",
      breadcrumb: [
        {
          text: "g.AcceptanceAndRejectionCriteriaList",
          active: true,
        },
      ],
    },
  },
];
