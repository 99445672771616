import { Ability } from "@casl/ability";
import { initialAbility } from "./config";

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem("userData"));
const accessToken = localStorage.getItem("accessToken");

const existingAbility = () => {
  // if (!!userData && userData.role == "Super Admin") {
  //   return [
  //     {
  //       action: "manage",
  //       subject: "all",
  //     },
  //   ];
  // }

  if (!!userData && userData.role && accessToken) {
    return [
      {
        action: "manage",
        subject: "all",
      },
    ];
  }

  return null;
};

export default new Ability(existingAbility() || initialAbility);
