export default [
  {
    path: "/list-correct-action-type",
    name: "ListCorrectActionType",
    component: () =>
      import("@/views/pages/correctActionType/listCorrectActionType.vue"),
    meta: {
      pageTitle: "g.listCorrectActionType",
      breadcrumb: [
        {
          text: "g.listCorrectActionType",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-correct-action-type",
    name: "AddCorrectActionType",
    component: () =>
      import("@/views/pages/correctActionType/addCorrectActionType.vue"),
    meta: {
      pageTitle: "g.AddCorrectActionType",
      breadcrumb: [
        {
          text: "g.AddCorrectActionType",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-correct-action-type/:id",
    name: "EditCorrectActionType",
    component: () =>
      import("@/views/pages/correctActionType/addCorrectActionType.vue"),
    meta: {
      pageTitle: "g.EditCorrectActionType",
      breadcrumb: [
        {
          text: "g.EditCorrectActionType",
          active: true,
        },
      ],
    },
  },
];
