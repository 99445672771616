export default [
  // Scope of accreditation of ELE Lab

  {
    path: "/add-scope-of-accreditation-of-ELE-lab",
    name: "AddScopeOfAccreditationOfELELab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddScopeOfAccreditationOfELELab",
      breadcrumb: [
        {
          text: "g.AddScopeOfAccreditationOfELELab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-scope-of-accreditation-of-ELE-lab/:id",
    name: "EditScopeOfAccreditationOfELELab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditScopeOfAccreditationOfELELab",
      breadcrumb: [
        {
          text: "g.EditScopeOfAccreditationOfELELab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/scope-of-accreditation-of-ELE-lab-list",
    name: "ScopeOfAccreditationOfELELabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ScopeOfAccreditationOfELELabList",
      breadcrumb: [
        {
          text: "g.ScopeOfAccreditationOfELELabList",
          active: true,
        },
      ],
    },
  },

  // Scope of accreditation of TEX Lab

  {
    path: "/add-scope-of-accreditation-of-TEX-lab",
    name: "AddScopeOfAccreditationOfTEXLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddScopeOfAccreditationOfTEXLab",
      breadcrumb: [
        {
          text: "g.AddScopeOfAccreditationOfTEXLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-scope-of-accreditation-of-TEX-lab/:id",
    name: "EditScopeOfAccreditationOfTEXLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditScopeOfAccreditationOfTEXLab",
      breadcrumb: [
        {
          text: "g.EditScopeOfAccreditationOfTEXLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/scope-of-accreditation-of-TEX-lab-list",
    name: "ScopeOfAccreditationOfTEXLabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ScopeOfAccreditationOfTEXLabList",
      breadcrumb: [
        {
          text: "g.ScopeOfAccreditationOfTEXLabList",
          active: true,
        },
      ],
    },
  },

  // Scope of accreditation of GP Lab

  {
    path: "/add-scope-of-accreditation-of-GP-lab",
    name: "AddScopeOfAccreditationOfGPLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddScopeOfAccreditationOfGPLab",
      breadcrumb: [
        {
          text: "g.AddScopeOfAccreditationOfGPLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-scope-of-accreditation-of-GP-lab/:id",
    name: "EditScopeOfAccreditationOfGPLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditScopeOfAccreditationOfGPLab",
      breadcrumb: [
        {
          text: "g.EditScopeOfAccreditationOfGPLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/scope-of-accreditation-of-GP-lab-list",
    name: "ScopeOfAccreditationOfGPLabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ScopeOfAccreditationOfGPLabList",
      breadcrumb: [
        {
          text: "g.ScopeOfAccreditationOfGPLabList",
          active: true,
        },
      ],
    },
  },

  // Scope of accreditation Certificate of cosmetics Lab

  {
    path: "/add-scope-of-accreditation-certificate-of-cosmetics-lab",
    name: "AddScopeOfAccreditationCertificateOfCosmeticsLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddScopeOfAccreditationCertificateOfCosmeticsLab",
      breadcrumb: [
        {
          text: "g.AddScopeOfAccreditationCertificateOfCosmeticsLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-scope-of-accreditation-certificate-of-cosmetics-lab/:id",
    name: "EditScopeOfAccreditationCertificateOfCosmeticsLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditScopeOfAccreditationCertificateOfCosmeticsLab",
      breadcrumb: [
        {
          text: "g.EditScopeOfAccreditationCertificateOfCosmeticsLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/scope-of-accreditation-certificate-of-cosmetics-lab-list",
    name: "ScopeOfAccreditationCertificateOfCosmeticsLabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ScopeOfAccreditationCertificateOfCosmeticsLabList",
      breadcrumb: [
        {
          text: "g.ScopeOfAccreditationCertificateOfCosmeticsLabList",
          active: true,
        },
      ],
    },
  },

  // Scope of accreditation Certificate of SP Lab
  {
    path: "/add-scope-of-accreditation-certificate-of-SP-lab",
    name: "AddScopeOfAccreditationCertificateOfSPLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddScopeOfAccreditationCertificateOfSPLab",
      breadcrumb: [
        {
          text: "g.AddScopeOfAccreditationCertificateOfSPLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-scope-of-accreditation-certificate-of-SP-lab/:id",
    name: "EditScopeOfAccreditationCertificateOfSPLab",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditScopeOfAccreditationCertificateOfSPLab",
      breadcrumb: [
        {
          text: "g.EditScopeOfAccreditationCertificateOfSPLab",
          active: true,
        },
      ],
    },
  },
  {
    path: "/scope-of-accreditation-certificate-of-SP-lab-list",
    name: "ScopeOfAccreditationCertificateOfSPLabList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ScopeOfAccreditationCertificateOfSPLabList",
      breadcrumb: [
        {
          text: "g.ScopeOfAccreditationCertificateOfSPLabList",
          active: true,
        },
      ],
    },
  },

  // Scope of accreditation of IB

  {
    path: "/add-scope-of-accreditation-of-IB",
    name: "AddScopeOfAccreditationOfIB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddScopeOfAccreditationOfIB",
      breadcrumb: [
        {
          text: "g.AddScopeOfAccreditationOfIB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-scope-of-accreditation-of-IB/:id",
    name: "EditScopeOfAccreditationOfIB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditScopeOfAccreditationOfIB",
      breadcrumb: [
        {
          text: "g.EditScopeOfAccreditationOfIB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/scope-of-accreditation-of-IB-list",
    name: "ScopeOfAccreditationOfIBList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ScopeOfAccreditationOfIBList",
      breadcrumb: [
        {
          text: "g.ScopeOfAccreditationOfIBList",
          active: true,
        },
      ],
    },
  },

  // Scope of accreditation of CB

  {
    path: "/add-scope-of-accreditation-of-CB",
    name: "AddScopeOfAccreditationOfCB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddScopeOfAccreditationOfCB",
      breadcrumb: [
        {
          text: "g.AddScopeOfAccreditationOfCB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-scope-of-accreditation-of-CB/:id",
    name: "EditScopeOfAccreditationOfCB",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditScopeOfAccreditationOfCB",
      breadcrumb: [
        {
          text: "g.EditScopeOfAccreditationOfCB",
          active: true,
        },
      ],
    },
  },
  {
    path: "/scope-of-accreditation-of-CB-list",
    name: "ScopeOfAccreditationOfCBList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ScopeOfAccreditationOfCBList",
      breadcrumb: [
        {
          text: "g.ScopeOfAccreditationOfCBList",
          active: true,
        },
      ],
    },
  },
];
