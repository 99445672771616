export default [
    {
        path: "/maintenance-types-list",
        name: "maintenanceTypesList",
        component: () => import("@/views/pages/maintenanceTypes/maintenanceTypesList.vue"),
        meta: {
            pageTitle: "g.maintenanceTypesList",
            breadcrumb: [
                {
                    text: "g.maintenanceTypesList",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/add-maintenance-type",
        name: "addMaintenanceType",
        component: () => import("@/views/pages/maintenanceTypes/addMaintenanceType.vue"),
        meta: {
            pageTitle: "g.addMaintenanceType",
            breadcrumb: [
                {
                    text: "g.addMaintenanceType",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/edit-maintenance-type/:id",
        name: "editMaintenanceType",
        component: () => import("@/views/pages/maintenanceTypes/addMaintenanceType.vue"),
        meta: {
            pageTitle: "g.editMaintenanceType",
            breadcrumb: [
                {
                    text: "g.editMaintenanceType",
                    active: true,
                },
            ],
        },
    },
];
