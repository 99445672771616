export default [
  {
    path: "/add-service-purchase-request",
    name: "AddServicePurchaseRequest",
    component: () =>
      import(
        "@/views/pages/externallyProvidedProductsAndServices/servicePurchaseRequest/addServicePurchaseRequest.vue"
      ),
  },
  {
    path: "/services-purchase-request-list",
    name: "ServicesPurchaseRequestList",
    component: () =>
      import(
        "@/views/pages/externallyProvidedProductsAndServices/servicePurchaseRequest/servicesPurchaseRequestList.vue"
      ),
    meta: {
      pageTitle: "g.servicesPurchaseRequestList",
      breadcrumb: [
        {
          text: "g.servicesPurchaseRequestList",
          active: true,
        },
      ],
    },
  },
  {
    path: "/preview-request/:id",
    name: "PreviewRequest",
    component: () =>
      import(
        "@/views/pages/externallyProvidedProductsAndServices/servicePurchaseRequest/previewRequest.vue"
      ),
    meta: {
      pageTitle: "g.previewRequest",
      breadcrumb: [
        {
          text: "g.previewRequest",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-buy-request-type/",
    name: "AddBuyRequestType",
    component: () =>
      import(
        "@/views/pages/externallyProvidedProductsAndServices/buyRequest/addBuyRequestType.vue"
      ),
    meta: {
      pageTitle: "g.AddBuyRequestType",
      breadcrumb: [
        {
          text: "g.AddBuyRequestType",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-buy-request-type/:id",
    name: "EditBuyRequestType",
    component: () =>
      import(
        "@/views/pages/externallyProvidedProductsAndServices/buyRequest/addBuyRequestType.vue"
      ),
    meta: {
      pageTitle: "g.EditBuyRequestType",
      breadcrumb: [
        {
          text: "g.EditBuyRequestType",
          active: true,
        },
      ],
    },
  },
  {
    path: "/ListOfBuyRequestTypes",
    name: "ListOfBuyRequestTypes",
    component: () =>
      import(
        "@/views/pages/externallyProvidedProductsAndServices/buyRequest/buyRequestTypeList.vue"
      ),
    meta: {
      pageTitle: "g.ListOfBuyRequestTypes",
      breadcrumb: [
        {
          text: "g.ListOfBuyRequestTypes",
          active: true,
        },
      ],
    },
  },
];
