export default [
  // Organizational structure
  {
    path: "/add-organizational-structure",
    name: "AddOrganizationalStructure",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddOrganizationalStructure",
      breadcrumb: [
        {
          text: "g.AddOrganizationalStructure",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-organizational-structure/:id",
    name: "EditOrganizationalStructure",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditOrganizationalStructure",
      breadcrumb: [
        {
          text: "g.EditOrganizationalStructure",
          active: true,
        },
      ],
    },
  },
  {
    path: "/organizational-structure-list",
    name: "OrganizationalStructureList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.OrganizationalStructureList",
      breadcrumb: [
        {
          text: "g.OrganizationalStructureList",
          active: true,
        },
      ],
    },
  },

  // Relationship between the departments of various activities

  {
    path: "/Add-relationship-between-the-departments",
    name: "AddRelationshipBetweenTheDepartments",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddRelationshipBetweenTheDepartments",
      breadcrumb: [
        {
          text: "g.AddRelationshipBetweenTheDepartments",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-relationship-between-the-departments/:id",
    name: "EditRelationshipBetweenTheDepartments",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditRelationshipBetweenTheDepartments",
      breadcrumb: [
        {
          text: "g.EditRelationshipBetweenTheDepartments",
          active: true,
        },
      ],
    },
  },
  {
    path: "/relationship-between-the-departments-list",
    name: "RelationshipBetweenTheDepartmentsList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.RelationshipBetweenTheDepartmentsList",
      breadcrumb: [
        {
          text: "g.RelationshipBetweenTheDepartmentsList",
          active: true,
        },
      ],
    },
  },
  // Management Commitment of impartiality

  {
    path: "/add-management-commitment-of-impartiality",
    name: "AddManagementCommitmentOfImpartiality",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddManagementCommitmentOfImpartiality",
      breadcrumb: [
        {
          text: "g.AddManagementCommitmentOfImpartiality",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-management-commitment-of-impartiality/:id",
    name: "EditManagementCommitmentOfImpartiality",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditManagementCommitmentOfImpartiality",
      breadcrumb: [
        {
          text: "g.EditManagementCommitmentOfImpartiality",
          active: true,
        },
      ],
    },
  },
  {
    path: "/management-commitment-of-impartiality-list",
    name: "ManagementCommitmentOfImpartialityList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ManagementCommitmentOfImpartialityList",
      breadcrumb: [
        {
          text: "g.ManagementCommitmentOfImpartialityList",
          active: true,
        },
      ],
    },
  },

  // Committee of safeguarding impartiality
  {
    path: "/add-committee-of-safeguarding-impartiality",
    name: "AddCommitteeOfSafeguardingImpartiality",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddCommitteeOfSafeguardingImpartiality",
      breadcrumb: [
        {
          text: "g.AddCommitteeOfSafeguardingImpartiality",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-committee-of-safeguarding-impartiality/:id",
    name: "EditCommitteeOfSafeguardingImpartiality",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditCommitteeOfSafeguardingImpartiality",
      breadcrumb: [
        {
          text: "g.EditCommitteeOfSafeguardingImpartiality",
          active: true,
        },
      ],
    },
  },
  {
    path: "/committee-of-safeguarding-impartiality-list",
    name: "CommitteeOfSafeguardingImpartialityList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.CommitteeOfSafeguardingImpartialityList",
      breadcrumb: [
        {
          text: "g.CommitteeOfSafeguardingImpartialityList",
          active: true,
        },
      ],
    },
  },
  // Management Commitment of independence and non-conflict of interests

  {
    path: "/add-management-commitment-of-independence",
    name: "AddManagementCommitmentOfIndependence",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.AddManagementCommitmentOfIndependence",
      breadcrumb: [
        {
          text: "g.AddManagementCommitmentOfIndependence",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-management-commitment-of-independence/:id",
    name: "EditManagementCommitmentOfIndependence",
    component: () => import("@/views/pages/uploadFiles/uploadFiles.vue"),
    meta: {
      pageTitle: "g.EditManagementCommitmentOfIndependence",
      breadcrumb: [
        {
          text: "g.EditManagementCommitmentOfIndependence",
          active: true,
        },
      ],
    },
  },
  {
    path: "/management-commitment-of-independence-list",
    name: "ManagementCommitmentOfIndependenceList",
    component: () => import("@/views/pages/uploadFiles/lists.vue"),
    meta: {
      pageTitle: "g.ManagementCommitmentOfIndependenceList",
      breadcrumb: [
        {
          text: "g.ManagementCommitmentOfIndependenceList",
          active: true,
        },
      ],
    },
  },
];
