import axiosIns from "@axios";
import userAbility from "@/libs/acl";
import router from "@/router";

export default {
  state: {
    user: null,
    route: null,
    isBlocked: null,
    isUserStateLoading: true,
  },
  getters: {
    CHECK_IS_LOGIN_PAGE(state) {
      return state.route.name == "Login" ? true : false;
    },
  },
  mutations: {
    CHECK_USER_ABILITY(state, payload) {
      if (payload == "Super Admin") {
        userAbility.update([
          {
            action: "manage",
            subject: "all",
          },
        ]);
      }
    },

    SET_ROUTE(state, route) {
      state.route = route;
    },

    IS_BLOCKED(state, value) {
      state.isBlocked = value;
    },
  },
  actions: {
    FETCH_USER_DATA({ state, commit, getters }) {
      if (!getters.CHECK_IS_LOGIN_PAGE) {
        axiosIns
          .get("web/users")
          .then((response) => {
            state.isUserStateLoading = false;
            const user = response.data.data;
            state.user = user;
            commit("IS_BLOCKED", user.is_blocked);
            // ! pasued until contact with backend team because role not return
            // commit("CHECK_USER_ABILITY", user.role);
          })
          .catch((err) => {
            state.isUserStateLoading = false;
            console.log(err.response.data);
          });
      }
    },
  },
};
